import React from "react";
import { Gavel, LogOut } from "lucide-react";
import { Toaster } from "react-hot-toast";
import { useAuth } from "./hooks/useAuth";
import { BuyerInterface } from "./components/buyer/BuyerInterface";
import { OrganizerInterface } from "./components/organizer/OrganizerInterface";
import { AuthForm } from "./components/auth/AuthForm";
import { CollapsibleRules } from "./components/shared/CollapsibleRules";

function App() {
  const { user, loading, logout } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-gray-300">Loading...</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-900">
        <header className="bg-gray-800 border-b border-gray-700">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className="flex items-center gap-2">
              <Gavel size={24} className="text-indigo-500" />
              <h1 className="text-2xl font-bold text-gray-100">
                Encan Vilain Garcon
              </h1>
            </div>
          </div>
        </header>
        <AuthForm />
        <Toaster position="top-right" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <Toaster position="top-right" />
      <header className="bg-gray-800 border-b border-gray-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Gavel size={24} className="text-indigo-500" />
              <h1 className="text-2xl font-bold text-gray-100">Mini Auction</h1>
            </div>
            <div className="flex items-center gap-4">
              <span className="text-gray-300">
                Welcome, {user.email}
                {user.isOrganizer && " (Organizer)"}
              </span>
              <button
                onClick={() => logout()}
                className="flex items-center gap-2 text-gray-400 hover:text-gray-200"
              >
                <LogOut size={18} />
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <CollapsibleRules />

        {user.isOrganizer ? (
          <OrganizerInterface userId={user.uid} />
        ) : (
          <BuyerInterface userId={user.uid} />
        )}
      </main>
    </div>
  );
}

export default App;
