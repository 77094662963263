import { useState, useEffect } from "react";
import { ProductCard } from "./ProductCard";
import { useProducts } from "../../hooks/useProducts";
import { Product } from "../../types";
import { Search } from "lucide-react";

interface ItemListProps {
  eventId: string;
  userId: string;
}

export function ItemList({ eventId, userId }: ItemListProps) {
  const { products } = useProducts(eventId);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (products.length > 0) {
      const filteredProducts = products
        .filter((product) => {
          const userHasBid = product.bids?.some((bid) => bid.userId === userId);
          return !userHasBid;
        })
        .sort((a, b) => a.id.localeCompare(b.id));

      setDisplayedProducts(filteredProducts);
    }
    setLoading(false);
  }, [products, userId]);

  const filteredProducts = displayedProducts.filter((product) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      product.itemNumber.toLowerCase().includes(searchLower) ||
      product.title.toLowerCase().includes(searchLower) ||
      product.description.toLowerCase().includes(searchLower)
    );
  });

  if (loading) {
    return (
      <div className="text-center text-gray-400">
        Chargement des articles...
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Rechercher par numéro d'item ou mot-clé..."
          className="w-full px-4 py-2 pl-10 rounded-lg bg-gray-800 text-white border border-gray-700 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} userId={userId} />
        ))}
      </div>

      {filteredProducts.length === 0 && (
        <div className="text-center text-gray-400 py-8">
          Aucun article ne correspond à votre recherche
        </div>
      )}
    </div>
  );
}
