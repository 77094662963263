import { useEffect, useState } from "react";
import { useSalesStats } from "../../hooks/useSalesStats";
import { useProducts } from "../../hooks/useProducts";
import { BuyerCard } from "./BuyerCard";

interface BuyersListProps {
  eventId: string;
}

interface Buyer {
  id: string;
  name: string;
  email: string;
  phone: string;
  products: Array<{
    id: string;
    num: string;
    name: string;
    price: number;
    paid: boolean;
    thumbnailUrl: string;
    soldAt?: Date;
  }>;
  itemsBought: number;
  totalSpent: number;
  unpaidAmount: number;
}

export function BuyersList({ eventId }: BuyersListProps) {
  const { stats, loading } = useSalesStats(eventId);
  const { markAsPaid } = useProducts(eventId);
  const [buyers, setBuyers] = useState<Buyer[]>([]);

  useEffect(() => {
    if (!stats?.soldProducts) return;

    const buyersMap = new Map<string, Buyer>();

    stats.soldProducts.forEach((product) => {
      if (!product.soldTo) return;

      if (!buyersMap.has(product.soldTo)) {
        buyersMap.set(product.soldTo, {
          id: product.soldTo,
          name: product.buyerName || "Anonyme",
          email: product.buyerEmail || "Email inconnu",
          phone: product.buyerPhone || "Téléphone inconnu",
          products: [],
          itemsBought: 0,
          totalSpent: 0,
          unpaidAmount: 0,
        });
      }

      const buyer = buyersMap.get(product.soldTo)!;
      buyer.products.push({
        id: product.id,
        name: product.title,
        price: product.soldAmount ?? 0,
        thumbnailUrl: product.thumbnailUrls?.[0] || "",
        paid: product.paid ?? false,
        num: product.itemNumber ?? 0,
      });
    });

    const updatedBuyers = Array.from(buyersMap.values()).map((buyer) => {
      const totalSpent = buyer.products.reduce((sum, p) => sum + p.price, 0);
      const unpaidAmount = buyer.products.reduce(
        (sum, p) => sum + (p.paid ? 0 : p.price),
        0
      );

      return {
        ...buyer,
        itemsBought: buyer.products.length,
        totalSpent,
        unpaidAmount,
      };
    });

    setBuyers(updatedBuyers);
  }, [stats?.soldProducts]);

  const handleMarkAsPaid = async (productId: string) => {
    try {
      await markAsPaid(productId);
      setBuyers((prevBuyers) =>
        prevBuyers.map((buyer) => ({
          ...buyer,
          products: buyer.products.map((product) =>
            product.id === productId ? { ...product, paid: true } : product
          ),
          unpaidAmount: buyer.products.reduce(
            (acc, product) =>
              acc +
              (product.id === productId || product.paid ? 0 : product.price),
            0
          ),
        }))
      );
    } catch (error) {
      console.error("Erreur lors du marquage du paiement:", error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="text-center text-gray-400">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-400 mx-auto mb-4"></div>
        Chargement des acheteurs...
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-100">
          Liste des Acheteurs ({buyers.length})
        </h2>
        <span className="text-sm text-gray-400">
          ID Événement: #{eventId.substring(0, 8)}
        </span>
      </div>

      <div className="space-y-6">
        {buyers.map((buyer) => (
          <BuyerCard
            key={buyer.id}
            buyer={buyer}
            onMarkAsPaid={handleMarkAsPaid}
          />
        ))}
      </div>
    </div>
  );
}
