import React, { useState } from "react";
import { Clock, DollarSign, Gavel } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { Product } from "../../types";
import { ProductModal } from "./ProductModal";
import toast from "react-hot-toast";
import { useProducts } from "../../hooks/useProducts";
import { LazyImage } from "../common/LazyImage";

interface ProductCardProps {
  product: Product;
  userId: string;
}

export function ProductCard({ product, userId }: ProductCardProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bidAmount, setBidAmount] = useState<number>(0);
  const { placeBid, buyNow } = useProducts(product.eventId);

  // Calculer le montant d'enchère prérempli
  const minimumBid = product.minimumBid;
  const currentBid = product.bids?.length > 0 ? product.currentBid : 0;

  // Fonction à définir pour obtenir l'incrément minimum
  const getMinimumIncrement = (currentBid: number): number => {
    if (currentBid < 40) return 1;
    if (currentBid < 100) return 5;
    return 10;
  };

  const minimumIncrement = getMinimumIncrement(currentBid);

  // Vérifier s'il y a des enchères en cours
  const hasBids = product.bids && product.bids.length > 0;

  // Définir le montant d'enchère initial
  React.useEffect(() => {
    if (!hasBids) {
      setBidAmount(minimumBid);
    } else {
      setBidAmount(currentBid + minimumIncrement);
    }
  }, [hasBids, minimumBid, currentBid, minimumIncrement]);

  const handleBid = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await placeBid(product.id, bidAmount, userId);
      if (response) {
        toast.success("Enchère placée avec succès!");
      }
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Échec de l'enchère"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBuyNow = async () => {
    if (isSubmitting || product.sold) return;

    const confirmPurchase = window.confirm(
      "Êtes-vous sûr de vouloir acheter cet article ? Vous vous engagez à le payer."
    );

    if (!confirmPurchase) return;

    try {
      setIsSubmitting(true);
      await buyNow(product.id, userId);
      toast.success("Article acheté avec succès!");
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "Échec de l'achat");
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatTimeRemaining = (endTime: any) => {
    try {
      if (endTime && typeof endTime.toDate === "function") {
        endTime = endTime.toDate();
      } else if (!(endTime instanceof Date)) {
        endTime = new Date(endTime);
      }

      if (isNaN(endTime.getTime())) {
        return "Date invalide";
      }

      if (endTime < new Date()) {
        return "Terminé";
      }

      return formatDistanceToNow(endTime, { addSuffix: true });
    } catch (error) {
      console.error("Erreur de formatage de la date:", error);
      return "Date invalide";
    }
  };

  // Determine the bid status for the current user
  const getBidStatus = () => {
    if (!product.bids || product.bids.length === 0) {
      return "Be the first to bid";
    }

    const highestBid = product.bids.reduce((max, bid) =>
      bid.amount > max.amount ? bid : max
    );

    if (highestBid.userId === userId) {
      return "Highest";
    }

    const userBid = product.bids.find((bid) => bid.userId === userId);
    if (userBid) {
      return "Outbid";
    }

    return null;
  };

  const bidStatus = getBidStatus();

  return (
    <>
      <div className="card group">
        <div
          className="relative h-48 overflow-hidden rounded-t-xl cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          <LazyImage
            thumbnailSrc={product.thumbnailUrl}
            fullSrc={product.imageUrls[0]}
            alt={product.title}
            className="w-full h-48"
          />
          {product.sold && (
            <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
              <span className="text-white text-xl font-bold">SOLD</span>
            </div>
          )}
          <div className="absolute top-2 right-2 bg-black/70 text-white px-3 py-1 rounded-full flex items-center gap-1 backdrop-blur-sm">
            <Clock size={14} />
            <span className="text-sm">
              {formatTimeRemaining(product.endTime)}
            </span>
          </div>
          {bidStatus && (
            <div
              className={`absolute bottom-2 left-2 px-2 py-1 rounded-full text-sm font-semibold ${
                bidStatus === "Highest"
                  ? "bg-green-500 text-white"
                  : bidStatus === "Outbid"
                  ? "bg-red-500 text-white"
                  : "bg-gray-500 text-white"
              }`}
            >
              {bidStatus}
            </div>
          )}
        </div>

        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold text-gray-100">
              {product.itemNumber} - {product.title}
            </h3>
          </div>
          <p className="text-gray-400 text-sm mb-4">{product.description}</p>

          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center gap-2">
              <Gavel size={18} className="text-indigo-400" />
              <span className="font-medium text-gray-300">
                {product.bids && product.bids.length > 0
                  ? "Current Bid:"
                  : "Minimum Bid:"}
              </span>
              <span className="text-indigo-400 font-bold">
                $
                {product.bids && product.bids.length > 0
                  ? product.currentBid
                  : product.minimumBid}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <DollarSign size={18} className="text-emerald-400" />
              <span className="font-medium text-gray-300">Buy Now:</span>
              <span className="text-emerald-400 font-bold">
                ${product.buyNowPrice}
              </span>
            </div>
          </div>

          {!product.sold && (
            <div className="flex gap-2">
              <div className="flex-1">
                <input
                  type="number"
                  value={bidAmount}
                  onChange={(e) => setBidAmount(Number(e.target.value))}
                  className="w-full px-3 py-2 rounded-lg input"
                  min={minimumBid} // Utiliser minimumBid comme valeur minimale
                  step={1}
                />
              </div>
              <button
                onClick={handleBid}
                disabled={isSubmitting}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Place Bid
              </button>
              <button
                onClick={handleBuyNow}
                disabled={isSubmitting || product.sold}
                className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Buy Now
              </button>
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <ProductModal
          product={product}
          onClose={() => setShowModal(false)}
          userId={userId}
        />
      )}
    </>
  );
}
