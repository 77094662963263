import React, { useState, useRef, useEffect } from "react";
import { Plus } from "lucide-react";
import { useProducts } from "../../hooks/useProducts";
import { useEvents } from "../../hooks/useEvents";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";

interface CreateProductFormProps {
  eventId: string;
  onClose: () => void;
}

export function CreateProductForm({
  eventId,
  onClose,
}: CreateProductFormProps) {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { createProduct, generateItemNumber } = useProducts(eventId);
  const { getEvent } = useEvents();
  const [itemNumber, setItemNumber] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Get event end time
  // const event = getEvent(eventId); // Commenté pour éviter la boucle

  // Initialiser endDate avec la date de fin de l'événement
  const [endDate, setEndDate] = useState<Date>(new Date());

  // Mettre à jour endDate quand l'événement est chargé
  useEffect(() => {
    const loadEvent = async () => {
      const eventData = await getEvent(eventId); // Appel direct à getEvent
      if (eventData?.endDate) {
        setEndDate(new Date(eventData.endDate.seconds * 1000));
        console.log(eventData.endDate);
      }
    };
    loadEvent();
  }, [eventId]); // Changer la dépendance à eventId

  // Charger le numéro de produit au montage du composant
  useEffect(() => {
    const loadItemNumber = async () => {
      const newItemNumber = await generateItemNumber();
      setItemNumber(newItemNumber);
    };
    loadItemNumber();
  }, []);

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const newImages = Array.from(e.target.files);
    setSelectedImages((prev) => [...prev, ...newImages]);

    // Create preview URLs
    const newPreviewUrls = newImages.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
  };

  const removeImage = (index: number) => {
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));
    URL.revokeObjectURL(previewUrls[index]);
    setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const formData = new FormData(e.currentTarget);

      if (!endDate) {
        throw new Error("Date de fin de l'événement non disponible");
      }

      const productData = {
        itemNumber: formData.get("itemNumber") as string,
        title: formData.get("title") as string,
        description: formData.get("description") as string,
        minimumBid: Number(formData.get("minimumBid")),
        buyNowPrice: Number(formData.get("buyNowPrice")),
        endTime: endDate, // Utiliser directement le Timestamp de l'événement
      };

      await createProduct(productData, selectedImages);
      onClose();
    } catch (error) {
      console.error("Erreur lors de la création du produit:", error);
      toast.error(
        error instanceof Error
          ? error.message
          : "Erreur lors de la création du produit"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[80vh] overflow-y-auto">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Numéro de produit
            </label>
            <input
              type="text"
              name="itemNumber"
              value={itemNumber}
              onChange={(e) => setItemNumber(e.target.value)}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
            />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Titre
            </label>
            <input
              type="text"
              id="title"
              name="title"
              required
              className="w-full px-3 py-2 rounded-lg input"
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              required
              rows={3}
              className="w-full px-3 py-2 rounded-lg input"
            />
          </div>

          <div>
            <label
              htmlFor="images"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Images
            </label>
            <input
              type="file"
              id="images"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              ref={fileInputRef}
              className="hidden"
            />
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="w-full px-3 py-2 border-2 border-dashed border-gray-600 rounded-lg text-gray-400 hover:text-gray-300 hover:border-gray-500 transition-colors"
            >
              Cliquez pour sélectionner des images
            </button>
            {previewUrls.length > 0 && (
              <div className="mt-2 grid grid-cols-4 gap-2">
                {previewUrls.map((url, index) => (
                  <div key={url} className="relative group">
                    <img
                      src={url}
                      alt={`Preview ${index + 1}`}
                      className="w-full h-24 object-cover rounded"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      <Plus className="rotate-45" size={16} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="minimumBid"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Enchère minimum ($)
            </label>
            <input
              type="number"
              id="minimumBid"
              name="minimumBid"
              required
              min="0"
              step="0.01"
              className="w-full px-3 py-2 rounded-lg input"
            />
          </div>

          <div>
            <label
              htmlFor="buyNowPrice"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Prix d'achat immédiat ($)
            </label>
            <input
              type="number"
              id="buyNowPrice"
              name="buyNowPrice"
              required
              min="0"
              step="0.01"
              className="w-full px-3 py-2 rounded-lg input"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Date de fermeture.
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => {
                if (date) {
                  setEndDate(date);
                }
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
            />
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-gray-100"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Ajouter l'article
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
