import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { Product, User, Bid } from "../types";
import { format, subDays } from "date-fns";

interface DailyStats {
  timestamp: Date;
  cumulativeSales: number;
  cumulativeBids: number;
}

interface SalesStats {
  totalSales: number;
  totalCurrentBids: number;
  totalItems: number;
  soldItems: number;
  averageBasket: number;
  uniqueBidders: number;
  soldProducts: Product[];
  recentBids: Bid[];
  hourlyStats: DailyStats[];
}

interface UserInfo {
  displayName: string;
  email: string;
  phone: string;
}

export function useSalesStats(eventId: string) {
  const [stats, setStats] = useState<SalesStats>({
    totalSales: 0,
    totalCurrentBids: 0,
    totalItems: 0,
    soldItems: 0,
    bidItems: 0,
    averageBasket: 0,
    uniqueBidders: 0,
    soldProducts: [],
    recentBids: [],
    hourlyStats: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        console.log(
          "📊 Récupération des statistiques pour l'eventId:",
          eventId
        );

        // 1. Récupérer tous les produits de l'événement
        const productsRef = collection(db, "products");
        const productsQuery = query(
          productsRef,
          where("eventId", "==", eventId)
        );
        const productsSnapshot = await getDocs(productsQuery);
        const products = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Product[];

        setStats((prev) => ({ ...prev, totalItems: products.length }));

        // 2. Séparer les produits vendus et non vendus
        const soldProducts = products.filter((product) => product.sold);
        const unsoldProducts = products.filter((product) => !product.sold);

        // sort sold products by soldAt
        soldProducts.sort((a, b) => {
          const dateA =
            a.soldAt instanceof Timestamp
              ? a.soldAt.toDate()
              : new Date(a.soldAt || 0);
          const dateB =
            b.soldAt instanceof Timestamp
              ? b.soldAt.toDate()
              : new Date(b.soldAt || 0);
          return dateB.getTime() - dateA.getTime();
        });

        // 3. Calculer les ventes totales
        const totalSales = soldProducts.reduce(
          (sum, product) => sum + (product.soldAmount || 0),
          0
        );

        // 4. Calculer les enchères en cours (somme des plus hauts bids des items non vendus)
        const totalCurrentBids = unsoldProducts.reduce((sum, product) => {
          if (product.bids && product.bids.length > 0) {
            const highestBid = product.bids.reduce(
              (prev, current) =>
                prev.amount > current.amount ? prev : current,
              { amount: 0 } // Valeur initiale
            );
            return sum + (highestBid.amount || 0);
          }
          return sum;
        }, 0);

        // calculet le num d'item unsold qui on au moins un bid
        const bidItems = unsoldProducts.filter(
          (product) => product.bids && product.bids.length > 0
        ).length;

        // 5. Calculer la proportion d'articles vendus
        const soldItems = soldProducts.length;
        const proportionSold =
          products.length > 0 ? (soldItems / products.length) * 100 : 0;

        // 6. Calculer le nombre total d'enchérisseurs uniques
        const bidderSet = new Set<string>();
        products.forEach((product) => {
          product.bids?.forEach((bid) => bidderSet.add(bid.userId));
        });
        const uniqueBidders = bidderSet.size;

        // 7. Calculer le panier moyen
        const averageBasket =
          uniqueBidders > 0
            ? (totalSales + totalCurrentBids) / uniqueBidders
            : 0;

        // Fonction utilitaire pour récupérer les infos utilisateur
        const getUserInfo = async (userId: string): Promise<UserInfo> => {
          try {
            const userDoc = await getDoc(doc(db, "users", userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              return {
                displayName: userData.fullName || "Anonyme",
                email: userData.email || "Email inconnu",
                phone: userData.phoneNumber || "Téléphone inconnu",
              };
            }
            return {
              displayName: "Anonyme",
              email: "Email inconnu",
              phone: "Téléphone inconnu",
            };
          } catch (error) {
            console.error(
              `Erreur lors de la récupération de l'utilisateur ${userId}:`,
              error
            );
            return {
              displayName: "Anonyme",
              email: "Email inconnu",
              phone: "Téléphone inconnu",
            };
          }
        };

        // Récupérer les infos de tous les utilisateurs uniques (enchérisseurs et acheteurs)
        const uniqueUserIds = new Set<string>();
        products.forEach((product) => {
          if (product.soldTo) uniqueUserIds.add(product.soldTo);
          product.bids?.forEach((bid) => uniqueUserIds.add(bid.userId));
        });

        const usersInfo = new Map<string, UserInfo>();
        await Promise.all(
          Array.from(uniqueUserIds).map(async (userId) => {
            const userInfo = await getUserInfo(userId);
            usersInfo.set(userId, userInfo);
          })
        );

        // 8. Récupérer les 30 dernières enchères avec les infos utilisateurs complètes
        const recentBids = await Promise.all(
          products
            .flatMap((product) => {
              if (!product.bids) return [];

              return Promise.all(
                product.bids.map(async (bid) => {
                  // Récupérer les infos de l'utilisateur
                  const userDoc = await getDoc(doc(db, "users", bid.userId));
                  const userData = userDoc.data();

                  return {
                    id: bid.id,
                    itemId: product.itemNumber || "N/A",
                    itemTitle: product.title,
                    amount: bid.amount,
                    date: bid.timestamp,
                    buyerId: bid.userId,
                    buyerName: userData?.fullName || "Anonyme",
                    buyerEmail: userData?.email || "Email inconnu",
                    buyerPhone: userData?.phoneNumber || "Téléphone inconnu",
                    itemThumbnail:
                      product.thumbnailUrls?.[0] || product.imageUrls?.[0],
                  };
                })
              );
            })
            .flat()
        );

        // Mettre à jour les produits vendus avec les infos utilisateurs
        const enrichedSoldProducts = await Promise.all(
          soldProducts.map(async (product) => {
            if (product.soldTo) {
              const userInfo =
                usersInfo.get(product.soldTo) ||
                (await getUserInfo(product.soldTo));
              return {
                ...product,
                buyerName: userInfo.displayName,
                buyerEmail: userInfo.email,
                buyerPhone: userInfo.phone,
              };
            }
            return product;
          })
        );

        // Trier par date décroissante et prendre les 30 dernières
        const sortedBids = recentBids
          .sort((a, b) => {
            const dateA = new Date(a.date || 0);
            const dateB = new Date(b.date || 0);
            return dateB.getTime() - dateA.getTime();
          })
          .slice(0, 30);

        // 9. Calculer les statistiques horaires cumulatives pour les 7 derniers jours
        const sevenDaysAgo = subDays(new Date(), 7);
        const relevantSales = soldProducts.filter((product) =>
          product.soldAt instanceof Timestamp
            ? product.soldAt.toDate() >= sevenDaysAgo
            : new Date(product.soldAt || 0) >= sevenDaysAgo
        );

        const relevantBids = unsoldProducts.flatMap(
          (product) => product.bids || []
        );

        const combinedEvents = [
          ...relevantSales.map((product) => ({
            timestamp:
              product.soldAt instanceof Timestamp
                ? product.soldAt.toDate()
                : new Date(product.soldAt || 0),
            amount: product.soldAmount || 0,
            type: "sale" as const,
          })),
          ...relevantBids.map((bid) => ({
            timestamp:
              bid.timestamp instanceof Timestamp
                ? bid.timestamp.toDate()
                : new Date(bid.timestamp),
            amount: bid.amount,
            type: "bid" as const,
          })),
        ];

        combinedEvents.sort(
          (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
        );

        let cumulativeSales = 0;
        let cumulativeBids = 0;
        const hourlyStatsMap: { [key: string]: DailyStats } = {};

        combinedEvents.forEach((event) => {
          const hour = format(event.timestamp, "yyyy-MM-dd HH:00");
          if (!hourlyStatsMap[hour]) {
            hourlyStatsMap[hour] = {
              timestamp: new Date(hour),
              cumulativeSales: cumulativeSales,
              cumulativeBids: cumulativeBids,
            };
          }

          if (event.type === "sale") {
            cumulativeSales += event.amount;
          } else if (event.type === "bid") {
            cumulativeBids += event.amount;
          }

          hourlyStatsMap[hour] = {
            timestamp: new Date(hour),
            cumulativeSales,
            cumulativeBids,
          };
        });

        const hourlyStats: DailyStats[] = Object.values(hourlyStatsMap).sort(
          (a, b) => a.timestamp.getTime() - b.timestamp.getTime()
        );

        setStats({
          totalSales,
          totalCurrentBids,
          totalItems: products.length,
          soldItems,
          averageBasket,
          uniqueBidders,
          soldProducts: enrichedSoldProducts,
          recentBids: sortedBids,
          hourlyStats,
          bidItems,
        });

        console.log("📈 Statistiques récupérées:", {
          totalSales,
          totalCurrentBids,
          soldItems,
          proportionSold,
          uniqueBidders,
          averageBasket,
          recentBids,
          hourlyStats,
        });
      } catch (error) {
        console.error(
          "❌ Erreur lors de la récupération des statistiques de ventes:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchStats();
    }
  }, [eventId]);

  return { stats, loading };
}
