import { useState, useEffect } from "react";
import { Product } from "../../types";
import { ProductCard } from "./ProductCard";
import { useProducts } from "../../hooks/useProducts";

export function MyBids({
  eventId,
  userId,
}: {
  eventId: string;
  userId: string;
}) {
  const [biddedProducts, setBiddedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getUserBidedProducts } = useProducts(eventId);

  useEffect(() => {
    let mounted = true;

    const loadBiddedProducts = async () => {
      if (!eventId || !userId) {
        setError("ID d'événement ou ID utilisateur manquant");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const products = await getUserBidedProducts(userId);
        if (mounted) {
          // Trier les produits : surenchéris d'abord, puis par date de dernière enchère
          const sortedProducts = products.sort((a, b) => {
            // Vérifier si l'utilisateur est le dernier enchérisseur
            const aIsHighestBidder =
              a.bids[a.bids.length - 1]?.userId === userId;
            const bIsHighestBidder =
              b.bids[b.bids.length - 1]?.userId === userId;

            // Si l'un est surenchéri et l'autre non
            if (aIsHighestBidder !== bIsHighestBidder) {
              return aIsHighestBidder ? 1 : -1; // Mettre les surenchéris en premier
            }

            // Si même statut, trier par date de dernière enchère
            const aLastBid = a.bids[a.bids.length - 1]?.timestamp.seconds || 0;
            const bLastBid = b.bids[b.bids.length - 1]?.timestamp.seconds || 0;
            return bLastBid - aLastBid; // Plus récent en premier
          });

          setBiddedProducts(sortedProducts);
        }
      } catch (err) {
        if (mounted) {
          console.error("Error loading bidded products:", err);
          setError("Erreur lors du chargement des enchères");
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadBiddedProducts();

    return () => {
      mounted = false;
    };
  }, [eventId, userId]);

  // Séparer les produits en deux groupes
  const outbidProducts = biddedProducts.filter(
    (product) => product.bids[product.bids.length - 1]?.userId !== userId
  );
  const winningProducts = biddedProducts.filter(
    (product) => product.bids[product.bids.length - 1]?.userId === userId
  );

  if (loading) {
    return (
      <div className="text-center text-gray-400">
        Chargement des enchères...
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-red-400">{error}</div>;
  }

  if (!biddedProducts || biddedProducts.length === 0) {
    return (
      <div className="text-center text-gray-400">
        Vous n'avez pas encore placé d'enchères sur cet événement
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {/* Produits surenchéris */}
      {outbidProducts.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-red-400 mb-4">
            Enchères surpassées ({outbidProducts.length})
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {outbidProducts.map((product: Product) => (
              <ProductCard key={product.id} product={product} userId={userId} />
            ))}
          </div>
        </div>
      )}

      {/* Produits où l'utilisateur mène */}
      {winningProducts.length > 0 && (
        <div>
          <h3 className="text-lg font-medium text-green-400 mb-4">
            Enchères en tête ({winningProducts.length})
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {winningProducts.map((product: Product) => (
              <ProductCard key={product.id} product={product} userId={userId} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
