import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../lib/firebase";

interface Payment {
  id: string;
  eventId: string;
  buyerId: string;
  itemId: string;
  itemTitle: string;
  amount: number;
  dueDate: Date;
  isPaid: boolean;
  paidAt?: Date;
}

export function usePayments(eventId: string) {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const paymentsRef = collection(db, "payments");
        const q = query(
          paymentsRef,
          where("eventId", "==", eventId),
          orderBy("dueDate", "asc")
        );

        const snapshot = await getDocs(q);
        const paymentsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dueDate: doc.data().dueDate.toDate(),
          paidAt: doc.data().paidAt?.toDate(),
        })) as Payment[];

        setPayments(paymentsData);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setLoading(false);
      }
    };

    if (eventId) {
      fetchPayments();
    }
  }, [eventId]);

  const markAsPaid = async (paymentId: string) => {
    try {
      const paymentRef = doc(db, "payments", paymentId);
      const now = new Date();

      await updateDoc(paymentRef, {
        isPaid: true,
        paidAt: now,
      });

      // Mettre à jour l'état local
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment.id === paymentId
            ? { ...payment, isPaid: true, paidAt: now }
            : payment
        )
      );

      return true;
    } catch (error) {
      console.error("Error marking payment as paid:", error);
      throw error;
    }
  };

  return {
    payments,
    loading,
    markAsPaid,
  };
}
