import { useEvents } from "../../hooks/useEvents";
import { format } from "date-fns";
import { Plus, ChevronDown, Calendar, Edit2, Trash2 } from "lucide-react";
import { CreateEventForm } from "./CreateEventForm";
import { EditEventForm } from "./EditEventForm";
import type { Event } from "../../types";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

interface EventSelectorProps {
  userId: string;
  selectedEventId: string | null;
  onEventSelect: (eventId: string) => void;
}

export function EventSelector({
  userId,
  selectedEventId,
  onEventSelect,
}: EventSelectorProps) {
  const { events, loading, deleteEvent } = useEvents(userId);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);

  useEffect(() => {
    if (!selectedEventId && events.length > 0 && !loading) {
      const sortedEvents = [...events].sort((a, b) => {
        const dateA = new Date(a.endDate.toString());
        const dateB = new Date(b.endDate.toString());
        return dateA.getTime() - dateB.getTime();
      });

      const nextEvent = sortedEvents[0];
      if (nextEvent) {
        onEventSelect(nextEvent.id);
      }
    }
  }, [events, selectedEventId, onEventSelect, loading]);

  console.log("🎯 EventSelector state:", {
    loading,
    eventsCount: events.length,
    events, // Ajout pour déboguer
  });

  const handleDelete = async (eventId: string) => {
    if (!confirm("Êtes-vous sûr de vouloir supprimer cet événement ?")) return;

    try {
      await deleteEvent(eventId);
      toast.success("Événement supprimé avec succès");
      if (selectedEventId === eventId) {
        setIsDropdownOpen(false);
        onEventSelect("");
      }
    } catch (error) {
      toast.error("Échec de la suppression de l'événement");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4 bg-gray-800 rounded-lg">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
        <span className="ml-3 text-gray-400">Chargement des événements...</span>
      </div>
    );
  }

  return (
    <div className="relative space-y-4">
      <div className="flex justify-between items-center gap-4">
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex-1 flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700"
        >
          <div className="flex items-center gap-2">
            <Calendar size={20} className="text-indigo-400" />
            <span className="text-gray-100">
              {selectedEventId
                ? events.find((e) => e.id === selectedEventId)?.title
                : "Sélectionner un événement"}
            </span>
          </div>
          <ChevronDown size={20} className="text-gray-400" />
        </button>

        <button
          onClick={() => setIsCreating(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Plus size={20} />
          Nouvel Événement
        </button>
      </div>

      {isDropdownOpen && events.length > 0 && (
        <div className="absolute z-50 w-full mt-2 bg-gray-800 rounded-lg shadow-xl border border-gray-700 max-h-[300px] overflow-y-auto">
          <div className="divide-y divide-gray-700">
            {events.map((event) => (
              <div
                key={event.id}
                className="flex items-center justify-between px-4 py-3 hover:bg-gray-700 cursor-pointer"
              >
                <button
                  onClick={() => {
                    onEventSelect(event.id);
                    setIsDropdownOpen(false);
                  }}
                  className="flex-1 text-left"
                >
                  <div className="font-medium text-gray-100">{event.title}</div>
                  <div className="text-sm text-gray-400">
                    {format(new Date(event.startDate.toString()), "dd/MM/yyyy")}{" "}
                    -{format(new Date(event.endDate.toString()), "dd/MM/yyyy")}
                  </div>
                </button>
                <div className="flex gap-2 ml-4">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingEvent(event);
                    }}
                    className="p-2 text-gray-400 hover:text-indigo-400 rounded-full hover:bg-gray-600"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(event.id);
                    }}
                    className="p-2 text-gray-400 hover:text-red-400 rounded-full hover:bg-gray-600"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isCreating && <CreateEventForm onClose={() => setIsCreating(false)} />}
      {editingEvent && (
        <EditEventForm
          event={editingEvent}
          onClose={() => setEditingEvent(null)}
        />
      )}
    </div>
  );
}
