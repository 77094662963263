import { Suspense, lazy } from "react";
import { useSalesStats } from "../../hooks/useSalesStats";
import { DollarSign, Package, BarChart2, Users, Target } from "lucide-react";
import { BuyersList } from "./BuyersList";

const SalesChart = lazy(() =>
  import("./SalesChart").then((module) => ({ default: module.default }))
);
const SoldProductsList = lazy(() =>
  import("./SoldProductsList").then((module) => ({ default: module.default }))
);
const BidsHistory = lazy(() =>
  import("./BidsHistory").then((module) => ({ default: module.default }))
);

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-64">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
  </div>
);

interface SalesOverviewProps {
  eventId: string;
}

export function SalesOverview({ eventId }: SalesOverviewProps) {
  const { stats, loading } = useSalesStats(eventId);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-8">
      {/* Graphique */}
      <Suspense fallback={<LoadingSpinner />}>
        <SalesChart hourlyStats={stats.hourlyStats} />
      </Suspense>

      {/* En-tête et Stats - Toujours visible immédiatement */}
      <StatsHeader eventId={eventId} />
      <StatsGrid stats={stats} />

      {/* Listes chargées progressivement */}
      <Suspense fallback={<LoadingSpinner />}>
        <SoldProductsList products={stats.soldProducts} />
      </Suspense>

      <Suspense fallback={<LoadingSpinner />}>
        <BidsHistory bids={stats.recentBids} />
      </Suspense>

      <BuyersList buyers={stats.buyers} />
    </div>
  );
}

// Composants extraits pour un rendu immédiat
const StatsHeader = ({ eventId }: { eventId: string }) => (
  <div className="flex justify-between items-center bg-gray-800/30 p-6 rounded-xl border border-gray-700/50">
    <div>
      <h2 className="text-2xl font-bold text-gray-100">Aperçu des Ventes</h2>
      <p className="text-gray-400 mt-1">
        Statistiques en temps réel de votre événement
      </p>
    </div>
    <div className="px-4 py-2 bg-indigo-500/10 rounded-lg border border-indigo-500/20">
      <span className="text-indigo-400 font-medium">
        #{eventId.substring(0, 8)}
      </span>
    </div>
  </div>
);

interface StatCardProps {
  icon: React.ComponentType<{ size: number; className: string }>;
  title: string;
  value: string | number;
  subtitle?: string;
}

const StatCard = ({ icon: Icon, title, value, subtitle }: StatCardProps) => (
  <div className="bg-gray-800/30 p-6 rounded-xl border border-gray-700/50 hover:bg-gray-800/40 transition-all duration-300">
    <div className="flex items-center gap-4">
      <div className="p-2 bg-gray-700/50 rounded-lg">
        <Icon size={20} className="text-indigo-400" />
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-400">{title}</h3>
        <p className="text-2xl font-bold text-gray-100 mt-1">{value}</p>
        {subtitle && <p className="text-sm text-gray-500 mt-1">{subtitle}</p>}
      </div>
    </div>
  </div>
);

const StatsGrid = ({ stats }: { stats: SalesStats }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
    <StatCard
      icon={DollarSign}
      title="Ventes Totales"
      value={`$${stats.totalSales.toLocaleString("fr-FR", {
        minimumFractionDigits: 2,
      })}`}
    />
    <StatCard
      icon={Target}
      title="Enchères en Cours"
      value={`$${stats.totalCurrentBids.toLocaleString("fr-FR", {
        minimumFractionDigits: 2,
      })}`}
    />
    <StatCard
      icon={Package}
      title="Articles Vendus"
      value={`${stats.soldItems} / ${stats.totalItems}`}
      subtitle={`${((stats.soldItems / stats.totalItems) * 100).toFixed(1)}%`}
    />
    <StatCard
      icon={Users}
      title="Articles Enchéris"
      value={`${stats.bidItems} / ${stats.totalItems - stats.soldItems}`}
      subtitle={`${(
        (stats.bidItems / (stats.totalItems - stats.soldItems)) *
        100
      ).toFixed(1)}%`}
    />
    <StatCard
      icon={BarChart2}
      title="Panier Moyen"
      value={`$${stats.averageBasket.toLocaleString("fr-FR", {
        minimumFractionDigits: 2,
      })}`}
    />
  </div>
);
