import { useState, useEffect, useCallback } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../lib/firebase";
import { Product } from "../types";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

// Fonction utilitaire pour redimensionner l'image dans le navigateur
async function resizeImage(file: File): Promise<Blob> {
  return new Promise((resolve) => {
    // Créer un canvas temporaire
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      // Calculer les nouvelles dimensions
      let width = img.width;
      let height = img.height;
      const MAX_SIZE = 400;

      if (width > height && width > MAX_SIZE) {
        height = (height * MAX_SIZE) / width;
        width = MAX_SIZE;
      } else if (height > MAX_SIZE) {
        width = (width * MAX_SIZE) / height;
        height = MAX_SIZE;
      }

      // Configurer le canvas
      canvas.width = width;
      canvas.height = height;

      // Dessiner l'image redimensionnée
      ctx?.drawImage(img, 0, 0, width, height);

      // Convertir en blob
      canvas.toBlob(
        (blob) => {
          if (blob) resolve(blob);
        },
        "image/jpeg",
        0.8
      );
    };

    // Charger l'image
    img.src = URL.createObjectURL(file);
  });
}

interface UpdateProductData {
  itemNumber?: string;
  title?: string;
  description?: string;
  minimumBid?: number;
  buyNowPrice?: number;
  endTime?: Date;
}

// Fonction utilitaire pour compresser l'image
async function compressImage(file: File): Promise<Blob> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculer les nouvelles dimensions
        let width = img.width;
        let height = img.height;
        const MAX_SIZE = 1024;

        if (width > height && width > MAX_SIZE) {
          height = (height * MAX_SIZE) / width;
          width = MAX_SIZE;
        } else if (height > MAX_SIZE) {
          width = (width * MAX_SIZE) / height;
          height = MAX_SIZE;
        }

        canvas.width = width;
        canvas.height = height;

        // Corriger l'orientation
        ctx?.save();
        ctx?.translate(width / 2, height / 2);
        ctx?.rotate(0);
        ctx?.drawImage(img, -width / 2, -height / 2, width, height);
        ctx?.restore();

        canvas.toBlob(
          (blob) => {
            if (blob) resolve(blob);
          },
          "image/jpeg",
          0.8
        );
      };

      img.src = e.target?.result as string;
    };

    reader.readAsDataURL(file);
  });
}

// Fonction pour télécharger une image avec metadata de cache
async function uploadImageWithCache(file: File, path: string) {
  const storageRef = ref(storage, path);

  // Compression de l'image avant upload
  const compressedFile = await compressImage(file);

  const metadata = {
    cacheControl: "public,max-age=31536000",
    contentType: "image/jpeg",
  };

  await uploadBytes(storageRef, compressedFile, metadata);
  return await getDownloadURL(storageRef);
}

// Fonction pour générer un hash unique
async function generateImageHash(file: File): Promise<string> {
  const buffer = await file.arrayBuffer();
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

export function useProducts(eventId: string) {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!eventId) return;

    setLoading(true);

    // Créer la requête pour les produits de l'événement
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("eventId", "==", eventId));

    // Mettre en place l'écouteur en temps réel
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const updatedProducts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Product[];

        setProducts(updatedProducts);
        setLoading(false);
      },
      (error) => {
        console.error("Erreur lors de l'écoute des produits:", error);
        setLoading(false);
      }
    );

    // Nettoyer l'écouteur lors du démontage
    return () => unsubscribe();
  }, [eventId]);

  const generateItemNumber = async () => {
    try {
      const productsRef = collection(db, "products");
      const q = query(productsRef, where("eventId", "==", eventId));
      const snapshot = await getDocs(q);
      const existingProducts = snapshot.docs.map((doc) => doc.data());

      // Trouver le plus grand numéro existant
      const maxNumber = existingProducts.reduce((max, product) => {
        const num = parseInt(product.itemNumber?.replace("IT", "") || "0");
        return Math.max(max, num);
      }, 0);

      // Générer le prochain numéro
      const nextNumber = maxNumber + 1;
      return `IT${nextNumber.toString().padStart(4, "0")}`;
    } catch (error) {
      console.error("Erreur lors de la génération du numéro:", error);
      return "IT0001";
    }
  };

  const isItemNumberUnique = async (itemNumber: string) => {
    const productsRef = collection(db, "products");
    const q = query(
      productsRef,
      where("eventId", "==", eventId),
      where("itemNumber", "==", itemNumber)
    );
    const snapshot = await getDocs(q);
    return snapshot.empty;
  };

  const createProduct = async (data: any, images: File[]) => {
    try {
      const imageUrls = [];
      const thumbnailUrls = [];

      // Upload des images et génération des thumbnails
      for (const image of images) {
        // Upload image originale
        const storageRef = ref(storage, `products/${eventId}/${uuidv4()}`);
        const snapshot = await uploadBytes(storageRef, image);
        const imageUrl = await getDownloadURL(snapshot.ref);
        imageUrls.push(imageUrl);

        // Générer et upload thumbnail
        const thumbnail = await resizeImage(image);
        const thumbnailRef = ref(
          storage,
          `products/${eventId}/thumbnails/${uuidv4()}`
        );
        const thumbnailSnapshot = await uploadBytes(thumbnailRef, thumbnail);
        const thumbnailUrl = await getDownloadURL(thumbnailSnapshot.ref);
        thumbnailUrls.push(thumbnailUrl);
      }

      const productData = {
        ...data,
        imageUrls,
        thumbnailUrls,
        eventId,
        currentBid: 0,
        bids: [],
        createdAt: new Date(),
        sold: false,
        endTime: Timestamp.fromDate(new Date(data.endTime)),
      };

      await addDoc(collection(db, "products"), productData);
      toast.success("Produit créé avec succès");
    } catch (error) {
      console.error("Erreur lors de la création du produit:", error);
      throw error;
    }
  };

  const updateProduct = async (
    productId: string,
    data: UpdateProductData,
    images: File[]
  ) => {
    try {
      // Si le numéro de produit est modifié, vérifier son unicité
      if (data.itemNumber) {
        const isUnique = await isItemNumberUnique(data.itemNumber);
        if (!isUnique) {
          throw new Error(
            "Ce numéro de produit existe déjà pour cet événement"
          );
        }
      }

      let imageUrls: string[] = [];

      // Uploader les nouvelles images si elles existent
      if (images.length > 0) {
        imageUrls = await Promise.all(
          images.map(async (image, index) => {
            const path = `products/${eventId}/${productId}/image_${index}`;
            return await uploadImageWithCache(image, path);
          })
        );
      }

      // Récupérer les images existantes si aucune nouvelle image n'est fournie
      const productRef = doc(db, "products", productId);
      const productDoc = await getDoc(productRef);
      const existingProductData = productDoc.data() as Product;

      // Mettre à jour le produit
      await updateDoc(productRef, {
        ...data,
        imageUrls:
          imageUrls.length > 0 ? imageUrls : existingProductData.imageUrls, // Conserver les images existantes si aucune nouvelle image n'est fournie
      });

      toast.success("Produit mis à jour avec succès");
      return true;
    } catch (error) {
      console.error("Erreur lors de la mise à jour du produit:", error);
      throw error;
    }
  };

  const deleteProduct = async (productId: string) => {
    const productRef = doc(db, "products", productId);
    try {
      await deleteDoc(productRef);
      return true;
    } catch (error) {
      console.error("Error deleting product:", error);
      throw error;
    }
  };

  const placeBid = async (
    productId: string,
    amount: number,
    userId: string
  ) => {
    // Demander confirmation avant de placer l'enchère
    const isConfirmed = window.confirm(
      `Êtes-vous sûr de vouloir placer une enchère de ${amount}$ ?`
    );

    if (!isConfirmed) {
      return false;
    }

    // Vérifier si l'utilisateur est connecté
    if (!userId) throw new Error("You must be logged in to place a bid");

    const productRef = doc(db, "products", productId);
    const product = products.find((p) => p.id === productId);

    // Vérifications du produit
    if (!product) throw new Error("Product not found");
    if (product.sold) throw new Error("This item has been sold");

    // Vérifier si l'enchère est terminée
    const now = new Date();
    const endTime = new Date(product.endTime.seconds * 1000);
    if (now > endTime) {
      throw new Error("This auction has ended");
    }

    // Vérifier si l'utilisateur enchérit sur son propre produit
    if (product.createdBy === userId) {
      throw new Error("You cannot bid on your own item");
    }

    // Vérifier le montant de l'enchère
    if (product.bids.length === 0 && amount < product.minimumBid) {
      throw new Error(`First bid must be at least $${product.minimumBid}`);
    } else if (product.bids.length > 0) {
      const minimumIncrement =
        product.currentBid < 40 ? 1 : product.currentBid < 100 ? 5 : 10;
      if (amount < product.currentBid + minimumIncrement) {
        throw new Error(`Minimum bid increment is $${minimumIncrement}`);
      }
    }

    // Vérifier si l'utilisateur a déjà la meilleure enchère
    const lastBid = product.bids[product.bids.length - 1];
    if (lastBid && lastBid.userId === userId) {
      throw new Error("You already have the highest bid");
    }

    try {
      const bid = {
        id: uuidv4(),
        userId,
        amount,
        timestamp: Timestamp.now(),
      };

      await updateDoc(productRef, {
        currentBid: amount,
        bids: [...product.bids, bid],
      });

      toast.success("Enchère placée avec succès !");
      return true;
    } catch (error) {
      console.error("Error placing bid:", error);
      throw error;
    }
  };

  const buyNow = async (productId: string, userId: string) => {
    const productRef = doc(db, "products", productId);
    const product = products.find((p) => p.id === productId);
    if (!product) throw new Error("Product not found");
    if (product.sold) throw new Error("This item has been sold");
    if (product.currentBid >= product.buyNowPrice * 0.75) {
      throw new Error("Buy now option is no longer available");
    }

    try {
      const buyNowBid = {
        id: uuidv4(),
        userId,
        amount: product.buyNowPrice,
        timestamp: Timestamp.now(),
      };

      await updateDoc(productRef, {
        sold: true,
        soldTo: userId,
        soldAt: Timestamp.now(),
        soldAmount: product.buyNowPrice,
        currentBid: product.buyNowPrice,
        bids: [...product.bids, buyNowBid],
      });

      toast.success("Item purchased successfully!");
      return true;
    } catch (error) {
      console.error("Error buying product:", error);
      throw error;
    }
  };

  const markAsPaid = async (productId: string) => {
    try {
      const productRef = doc(db, "products", productId);
      await updateDoc(productRef, {
        paid: true,
        paidAt: Timestamp.now(),
      });
    } catch (error) {
      console.error("Error marking product as paid:", error);
      throw error;
    }
  };

  const getUserBidedProducts = useCallback(
    async (userId: string): Promise<Product[]> => {
      try {
        if (!userId || !eventId) {
          console.log("Missing userId or eventId");
          return [];
        }

        const productsRef = collection(db, "products");
        const q = query(productsRef, where("eventId", "==", eventId));

        const querySnapshot = await getDocs(q);
        const allProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          endTime: doc.data().endTime.toDate(),
          createdAt: doc.data().createdAt.toDate(),
          soldAt: doc.data().soldAt?.toDate(),
          bids: doc.data().bids.map((bid: any) => ({
            ...bid,
            timestamp: bid.timestamp.toDate(),
          })),
        })) as Product[];

        // Filtrer les produits avec les enchères de l'utilisateur
        const biddedProducts = allProducts.filter((product) =>
          product.bids.some((bid) => bid.userId === userId)
        );

        return biddedProducts;
      } catch (error) {
        console.error("Error fetching bidded products:", error);
        return [];
      }
    },
    [eventId]
  );

  return {
    products,
    loading,
    createProduct,
    updateProduct,
    deleteProduct,
    placeBid,
    buyNow,
    getUserBidedProducts,
    generateItemNumber,
    markAsPaid,
  };
}
