import { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/Tabs";
import { EventSelector } from "./EventSelector";
import { ItemManager } from "./ItemManager";
import { SalesOverview } from "./SalesOverview";
import { BuyersList } from "./BuyersList";
import { PaymentTracker } from "./PaymentTracker";

interface OrganizerInterfaceProps {
  userId: string;
}

export function OrganizerInterface({ userId }: OrganizerInterfaceProps) {
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  console.log("OrganizerInterface rendering with userId:", userId);

  if (!userId) {
    console.error("OrganizerInterface: userId is undefined or null");
    return null;
  }

  return (
    <div className="mt-8">
      <EventSelector
        key={userId}
        userId={userId}
        selectedEventId={selectedEventId}
        onEventSelect={setSelectedEventId}
      />

      {selectedEventId ? (
        <div className="mt-6">
          <Tabs defaultValue="items">
            <TabsList className="grid grid-cols-4 gap-4 mb-6">
              <TabsTrigger value="items">Items</TabsTrigger>
              <TabsTrigger value="sales">Sales Overview</TabsTrigger>
              <TabsTrigger value="buyers">Buyers</TabsTrigger>
              <TabsTrigger value="payments">Payments</TabsTrigger>
            </TabsList>

            <TabsContent value="items">
              <ItemManager eventId={selectedEventId} userId={userId} />
            </TabsContent>

            <TabsContent value="sales">
              <SalesOverview eventId={selectedEventId} userId={userId} />
            </TabsContent>

            <TabsContent value="buyers">
              <BuyersList eventId={selectedEventId} userId={userId} />
            </TabsContent>

            <TabsContent value="payments">
              <PaymentTracker eventId={selectedEventId} userId={userId} />
            </TabsContent>
          </Tabs>
        </div>
      ) : (
        <div className="text-center text-gray-400 mt-8">
          Sélectionnez un événement pour voir les détails
        </div>
      )}
    </div>
  );
}
