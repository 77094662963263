import { useProducts } from "../../hooks/useProducts";
import { format } from "date-fns";
import { Edit2, Trash2, Plus, DollarSign, Clock } from "lucide-react";
import { CreateProductForm } from "./CreateProductForm";
import toast from "react-hot-toast";
import { EditProductForm } from "./EditProductForm";
import { Product } from "../../types";
import { useState } from "react";
import { LazyImage } from "../common/LazyImage";

interface ItemManagerProps {
  eventId: string;
  userId: string;
}

export function ItemManager({ eventId }: ItemManagerProps) {
  const { products, loading, deleteProduct } = useProducts(eventId);

  const [isCreating, setIsCreating] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);

  const handleDelete = async (productId: string) => {
    if (!confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) return;

    try {
      await deleteProduct(productId);
      toast.success("Article supprimé avec succès");
    } catch (error) {
      toast.error("Échec de la suppression de l'article");
    }
  };

  if (loading) {
    return (
      <div className="text-center text-gray-400">
        Chargement des articles...
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold text-gray-100">
            Gestion des Articles
          </h2>
          <span className="text-sm text-gray-400">
            ID Événement: #{eventId.substring(0, 8)}
          </span>
        </div>
        <button
          onClick={() => setIsCreating(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Plus size={20} />
          Ajouter un Article
        </button>
      </div>

      {isCreating && (
        <CreateProductForm
          eventId={eventId}
          onClose={() => setIsCreating(false)}
        />
      )}

      <div className="grid gap-4">
        {products.map((product) => (
          <div key={product.id} className="bg-gray-700/50 rounded-lg p-4">
            <div className="flex gap-4">
              <div className="w-24 h-24 rounded-lg overflow-hidden">
                <LazyImage
                  thumbnailSrc={product.thumbnailUrl}
                  fullSrc={product.imageUrls[0]}
                  alt={product.title}
                  className="w-24 h-24 object-cover"
                />
              </div>

              <div className="flex-1">
                <div className="flex justify-between">
                  <div>
                    <h3 className="text-lg font-medium text-gray-100">
                      {product.title}
                    </h3>
                    <p className="text-gray-400 mt-1">{product.description}</p>
                  </div>

                  <div className="flex gap-2">
                    <button
                      onClick={() => setEditingProduct(product)}
                      className="p-2 text-gray-400 hover:text-white bg-gray-800 rounded-lg"
                    >
                      <Edit2 size={18} />
                    </button>
                    <button
                      onClick={() => handleDelete(product.id)}
                      className="p-2 text-red-400 hover:text-red-300 bg-gray-800 rounded-lg"
                    >
                      <Trash2 size={18} />
                    </button>
                  </div>
                </div>

                <div className="flex items-center gap-4 mt-2 text-sm">
                  <div className="flex items-center gap-1 text-gray-300">
                    <DollarSign size={16} />
                    <span>Enchère actuelle: ${product.currentBid}</span>
                  </div>
                  <div className="flex items-center gap-1 text-gray-300">
                    <Clock size={16} />
                    <span>
                      Fin:{" "}
                      {format(new Date(product.endTime.seconds * 1000), "PPp")}
                    </span>
                  </div>
                  {product.sold && (
                    <span className="bg-green-500/20 text-green-400 px-2 py-0.5 rounded-full text-xs">
                      Vendu
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {editingProduct && (
        <EditProductForm
          product={editingProduct}
          onClose={() => setEditingProduct(null)}
        />
      )}
    </div>
  );
}
