import { useState, useEffect } from "react";
import { Calendar, ChevronDown, Plus, Trash2 } from "lucide-react";
import { format } from "date-fns";

import { useUserEvents } from "../../hooks/useUserEvents";

interface BuyerEventSelectorProps {
  userId: string;
  selectedEventId: string | null;
  onEventSelect: (eventId: string) => void;
}

export function BuyerEventSelector({
  userId,
  selectedEventId,
  onEventSelect,
}: BuyerEventSelectorProps) {
  const { userEvents: events } = useUserEvents(userId);
  const { addEventToUser, removeEventFromUser, refreshEvents } =
    useUserEvents(userId);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [newEventId, setNewEventId] = useState("");

  // Sélectionner automatiquement l'événement le plus récent
  useEffect(() => {
    if (!selectedEventId && events.length > 0) {
      // Trier les événements par date de fin (du plus proche au plus lointain)
      const sortedEvents = [...events].sort((a, b) => {
        const dateA = new Date(a.endDate.seconds * 1000);
        const dateB = new Date(b.endDate.seconds * 1000);
        return dateA.getTime() - dateB.getTime();
      });

      // Sélectionner l'événement avec la date de fin la plus proche
      const nextEvent = sortedEvents[0];
      if (nextEvent) {
        onEventSelect(nextEvent.id);
      }
    }
  }, [events, selectedEventId, onEventSelect]);

  const selectedEvent = events.find((e) => e.id === selectedEventId);

  return (
    <div className="relative">
      <div className="flex justify-between items-center gap-4">
        <button
          type="button"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex-1 flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700"
        >
          <div className="flex items-center gap-2">
            <Calendar size={20} className="text-indigo-400" />
            <span className="text-gray-100">
              {selectedEvent
                ? selectedEvent.title
                : "Sélectionner un événement"}
            </span>
          </div>
          <ChevronDown size={20} className="text-gray-400" />
        </button>

        <button
          type="button"
          onClick={() => setIsAddingEvent(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Plus size={20} />
          Rejoindre un encan
        </button>
      </div>

      {/* Dropdown des événements */}
      {isDropdownOpen && (
        <div className="absolute z-50 w-full mt-2 bg-gray-800 rounded-lg shadow-xl border border-gray-700">
          <div className="max-h-[300px] overflow-y-auto">
            {events.map((event) => {
              const startDate = new Date(event.startDate.seconds * 1000);
              const isValidDate = !isNaN(startDate.getTime());

              return (
                <div
                  key={event.id}
                  className="flex items-center justify-between p-3 hover:bg-gray-700 cursor-pointer"
                  onClick={() => {
                    onEventSelect(event.id);
                    setIsDropdownOpen(false);
                  }}
                >
                  <div>
                    <div className="text-gray-100">{event.title}</div>
                    <div className="text-sm text-gray-400">
                      {isValidDate
                        ? format(startDate, "dd/MM/yyyy")
                        : "Date invalide"}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        const success = await removeEventFromUser(event.id);
                        if (success) {
                          await refreshEvents();
                          window.location.reload();
                        }
                      } catch (error) {
                        console.error("Erreur lors de la suppression:", error);
                      }
                    }}
                    className="p-1 hover:bg-gray-600 rounded-full"
                  >
                    <Trash2
                      size={16}
                      className="text-gray-400 hover:text-red-400"
                    />
                  </button>
                </div>
              );
            })}
            {events.length === 0 && (
              <div className="p-4 text-center text-gray-400">
                Aucun événement ajouté
              </div>
            )}
          </div>
        </div>
      )}

      {/* Modal d'ajout d'événement */}
      {isAddingEvent && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md">
            <h3 className="text-xl font-semibold text-gray-100 mb-4">
              Rejoindre un encan
            </h3>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                try {
                  const success = await addEventToUser(newEventId);
                  if (success) {
                    setIsAddingEvent(false);
                    window.location.reload();
                  }
                } catch (error) {
                  console.error("Erreur lors de l'ajout:", error);
                }
              }}
            >
              <input
                type="text"
                value={newEventId}
                onChange={(e) => setNewEventId(e.target.value)}
                placeholder="ID de l'événement"
                className="w-full bg-gray-700 rounded px-3 py-2 text-gray-100"
              />
              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="button"
                  onClick={() => setIsAddingEvent(false)}
                  className="px-4 py-2 text-gray-400 hover:text-gray-300"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                >
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
