import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getStorage,
  connectStorageEmulator,
  ref,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCtJ7Ae0T7s_ATB6VFQA4CI0k5kXYWfXV4",
  authDomain: "auction-989b1.firebaseapp.com",
  projectId: "auction-989b1",
  storageBucket: "auction-989b1.firebasestorage.app",
  messagingSenderId: "479376558836",
  appId: "1:479376558836:web:5a1c298112a9e25798eca1",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Configuration du cache pour les requêtes Storage
const storageCache = new Map();

export async function getCachedImageUrl(path: string): Promise<string> {
  if (storageCache.has(path)) {
    return storageCache.get(path);
  }

  const imageRef = ref(storage, path);
  try {
    const url = await getDownloadURL(imageRef);
    storageCache.set(path, url);
    return url;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error;
  }
}
