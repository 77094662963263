import React, { useState } from "react";

import { useEvents } from "../../hooks/useEvents";
import toast from "react-hot-toast";
import { Event } from "../../types";
import { X } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import { Timestamp } from "firebase/firestore";
interface EditEventFormProps {
  event: Event;
  onClose: () => void;
}

export function EditEventForm({ event, onClose }: EditEventFormProps) {
  const { updateEvent } = useEvents();
  const [startDate, setStartDate] = useState<Date>(
    new Date(event.startDate.toString())
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(event.endDate.toString())
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      await updateEvent(event.id, {
        title: formData.get("title") as string,
        description: formData.get("description") as string,
        location: formData.get("location") as string,
        viewingDates: (formData.get("viewingDates") as string)
          .split(",")
          .map((d) => d.trim()),
        paymentMethods: (formData.get("paymentMethods") as string)
          .split(",")
          .map((m) => m.trim()),
        hasShipping: formData.get("hasShipping") === "on",
        shippingDetails: formData.get("shippingDetails") as string,
        pickupDetails: formData.get("pickupDetails") as string,
        startDate: Timestamp.fromDate(startDate),
        endDate: Timestamp.fromDate(endDate),
      });

      toast.success("Événement mis à jour avec succès");
      onClose();
    } catch (error) {
      toast.error("Échec de la mise à jour de l'événement");
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-100">
            Modifier l'événement
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Titre
            </label>
            <input
              name="title"
              defaultValue={event.title}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Description
            </label>
            <textarea
              name="description"
              defaultValue={event.description}
              rows={3}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Date de début
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => setStartDate(date || new Date())}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
              minDate={new Date()}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Date de fin
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => setEndDate(date || new Date())}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
              minDate={startDate}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Lieu
            </label>
            <input
              name="location"
              defaultValue={event.location}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Dates de visite (séparées par des virgules)
            </label>
            <input
              name="viewingDates"
              defaultValue={event.viewingDates.join(", ")}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Méthodes de paiement (séparées par des virgules)
            </label>
            <input
              name="paymentMethods"
              defaultValue={event.paymentMethods.join(", ")}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="hasShipping"
                name="hasShipping"
                defaultChecked={event.hasShipping}
                className="rounded border-gray-600 text-indigo-600"
              />
              <label htmlFor="hasShipping" className="text-sm text-gray-300">
                Livraison disponible
              </label>
            </div>

            <textarea
              name="shippingDetails"
              defaultValue={event.shippingDetails}
              placeholder="Détails et coûts de livraison..."
              rows={2}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Détails de récupération
            </label>
            <textarea
              name="pickupDetails"
              defaultValue={event.pickupDetails}
              placeholder="Adresse et instructions de récupération..."
              rows={2}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              required
            />
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-gray-100"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Enregistrer les modifications
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
