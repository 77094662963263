import React, { useState, useEffect, useRef } from "react";
import {
  X,
  ChevronLeft,
  ChevronRight,
  Clock,
  DollarSign,
  Gavel,
  Edit2,
} from "lucide-react";
import { formatDistanceToNow, format } from "date-fns";
import { Product } from "../../types";
import toast from "react-hot-toast";
import { useProducts } from "../../hooks/useProducts";
import { fr } from "date-fns/locale";

interface ProductModalProps {
  product: Product;
  onClose: () => void;

  userId: string;
  isOrganizer?: boolean;
}

// Define helper function first
const getMinimumIncrement = (currentBid: number): number => {
  if (currentBid < 40) return 1;
  if (currentBid < 100) return 5;
  return 10;
};

// Fonction utilitaire pour formater le temps restant
const formatTimeRemaining = (endTime: any) => {
  try {
    let date: Date;

    // Gestion des différents formats de date possibles
    if (endTime?.seconds) {
      // Si c'est un timestamp Firebase
      date = new Date(endTime.seconds * 1000);
    } else if (endTime instanceof Date) {
      // Si c'est déjà une Date
      date = endTime;
    } else {
      // Pour tout autre format
      date = new Date(endTime);
    }

    if (isNaN(date.getTime())) {
      return "Date invalide";
    }

    if (date < new Date()) {
      return "Terminé";
    }

    return formatDistanceToNow(date, {
      addSuffix: true,
      locale: fr,
    });
  } catch (error) {
    console.error("Erreur de formatage de la date:", error);
    return "Date invalide";
  }
};

// Fonction utilitaire pour convertir les timestamps en Date
const formatBidTimestamp = (timestamp: any): Date => {
  if (timestamp?.seconds) {
    return new Date(timestamp.seconds * 1000);
  }
  if (timestamp instanceof Date) {
    return timestamp;
  }
  return new Date(timestamp);
};

export function ProductModal({
  product,
  onClose,

  userId,
  isOrganizer,
}: ProductModalProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bidAmount, setBidAmount] = useState(
    product.currentBid + getMinimumIncrement(product.currentBid)
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const { updateProduct, placeBid, buyNow } = useProducts(product.eventId);

  // Update bid amount when current bid changes
  useEffect(() => {
    setBidAmount(product.currentBid + getMinimumIncrement(product.currentBid));
  }, [product.currentBid]);

  // Ajoutez cette logique dans le useEffect pour définir le montant d'enchère initial
  useEffect(() => {
    if (product.bids && product.bids.length > 0) {
      setBidAmount(
        product.currentBid + getMinimumIncrement(product.currentBid)
      );
    } else {
      setBidAmount(product.minimumBid); // Préremplir avec minimumBid si aucune enchère
    }
  }, [product.bids, product.currentBid, product.minimumBid]);

  const buyNowDisabled =
    product.currentBid >= product.buyNowPrice * 0.75 || product.sold;

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? product.imageUrls.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === product.imageUrls.length - 1 ? 0 : prev + 1
    );
  };

  const handleBid = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await placeBid(product.id, bidAmount, userId);
      if (response) {
        toast.success("Enchère placée avec succès!");
      }
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Failed to place bid"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBuyNow = async () => {
    if (isSubmitting || buyNowDisabled) return;

    try {
      setIsSubmitting(true);
      await buyNow(product.id, userId);
      toast.success("Item purchased successfully!");
      onClose();
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Failed to purchase item"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateProduct = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const formData = new FormData(e.currentTarget);

      const minimumBid = Number(formData.get("minimumBid"));
      const buyNowPrice = Number(formData.get("buyNowPrice"));

      if (minimumBid >= buyNowPrice) {
        throw new Error("Minimum bid must be less than buy now price");
      }

      await updateProduct(
        product.id,
        {
          title: formData.get("title") as string,
          description: formData.get("description") as string,
          minimumBid,
          buyNowPrice,
        },
        []
      );

      toast.success("Item updated successfully!");
      setIsEditing(false);
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Failed to update item"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle click outside to close
  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Handle escape key to close
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  // Handle touch swipe to close on mobile
  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    let touchStart = 0;
    let touchEnd = 0;

    const handleTouchStart = (e: TouchEvent) => {
      touchStart = e.changedTouches[0].screenY;
    };

    const handleTouchMove = (e: TouchEvent) => {
      touchEnd = e.changedTouches[0].screenY;
    };

    const handleTouchEnd = () => {
      const swipeLength = touchEnd - touchStart;
      if (swipeLength > 50) {
        // Swipe down
        onClose();
      }
    };

    modal.addEventListener("touchstart", handleTouchStart);
    modal.addEventListener("touchmove", handleTouchMove);
    modal.addEventListener("touchend", handleTouchEnd);

    return () => {
      modal.removeEventListener("touchstart", handleTouchStart);
      modal.removeEventListener("touchmove", handleTouchMove);
      modal.removeEventListener("touchend", handleTouchEnd);
    };
  }, [onClose]);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <div
      className="fixed inset-0 z-50 bg-black/75 flex items-center justify-center p-4 overflow-y-auto"
      onClick={handleBackdropClick}
    >
      <div
        ref={modalRef}
        className="bg-gray-800 rounded-xl max-w-3xl w-full overflow-hidden relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="sticky top-0 z-10 flex justify-end p-4 bg-gray-800 border-b border-gray-700">
          {isOrganizer && !isEditing && (
            <button
              onClick={() => setIsEditing(true)}
              className="absolute left-4 top-4 p-2 text-gray-400 hover:text-white bg-black/20 rounded-full"
            >
              <Edit2 size={20} />
            </button>
          )}
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-white bg-black/20 rounded-full"
          >
            <X size={20} />
          </button>
        </div>

        <div className="overflow-y-auto max-h-[calc(100vh-8rem)]">
          {isEditing ? (
            <form onSubmit={handleUpdateProduct} className="p-6">
              <h2 className="text-xl font-bold text-gray-100 mb-4">
                Edit Item
              </h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Title
                  </label>
                  <input
                    name="title"
                    defaultValue={product.title}
                    className="w-full px-3 py-2 rounded-lg input"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Description
                  </label>
                  <textarea
                    name="description"
                    defaultValue={product.description}
                    rows={3}
                    className="w-full px-3 py-2 rounded-lg input"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Minimum Bid
                  </label>
                  <input
                    type="number"
                    name="minimumBid"
                    defaultValue={product.minimumBid}
                    className="w-full px-3 py-2 rounded-lg input"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Buy Now Price
                  </label>
                  <input
                    type="number"
                    name="buyNowPrice"
                    defaultValue={product.buyNowPrice}
                    className="w-full px-3 py-2 rounded-lg input"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setIsEditing(false)}
                    className="px-4 py-2 text-gray-400 hover:text-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div className="relative h-96">
                <img
                  src={product.imageUrls[currentImageIndex]}
                  alt={product.title}
                  className="w-full h-full object-contain bg-black cursor-pointer"
                  onClick={handleImageClick}
                />
                {product.sold && (
                  <div className="absolute inset-0 bg-black/75 flex items-center justify-center">
                    <span className="text-white text-3xl font-bold">SOLD</span>
                  </div>
                )}
                {product.imageUrls.length > 1 && (
                  <>
                    <button
                      onClick={handlePrevImage}
                      className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-black/50 text-white rounded-full hover:bg-black/75"
                    >
                      <ChevronLeft size={24} />
                    </button>
                    <button
                      onClick={handleNextImage}
                      className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-black/50 text-white rounded-full hover:bg-black/75"
                    >
                      <ChevronRight size={24} />
                    </button>
                  </>
                )}
                <div className="absolute bottom-4 right-4 bg-black/70 text-white px-4 py-2 rounded-full flex items-center gap-2 backdrop-blur-sm">
                  <Clock size={16} />
                  <span>{formatTimeRemaining(product.endTime)}</span>
                </div>
              </div>

              {isFullScreen && (
                <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
                  <button
                    onClick={handleCloseFullScreen}
                    className="absolute top-4 right-4 text-white text-2xl"
                  >
                    <X />
                  </button>
                  <img
                    src={product.imageUrls[currentImageIndex]}
                    alt={product.title}
                    className="max-w-full max-h-full"
                  />
                </div>
              )}

              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-100 mb-2">
                  {product.title}
                </h2>
                <p className="text-gray-400 mb-6">{product.description}</p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-100 mb-4">
                      Bid History
                    </h3>
                    <div className="space-y-2">
                      {product.bids.length > 0 ? (
                        product.bids
                          .sort(
                            (a, b) => b.timestamp.seconds - a.timestamp.seconds
                          )
                          .map((bid) => (
                            <div
                              key={bid.id}
                              className="flex justify-between items-center text-sm bg-gray-700/50 p-2 rounded"
                            >
                              <span className="text-gray-300">
                                Bidder #{bid.userId.substring(0, 8)}
                              </span>
                              <div className="flex items-center gap-4">
                                <span className="text-indigo-400">
                                  ${bid.amount}
                                </span>
                                <span className="text-gray-500">
                                  {format(
                                    formatBidTimestamp(bid.timestamp),
                                    "MMM d, h:mm a"
                                  )}
                                </span>
                              </div>
                            </div>
                          ))
                      ) : (
                        <p className="text-gray-500">
                          No bids yet. Be the first to bid!
                        </p>
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="flex justify-between items-center mb-4">
                      <div className="flex items-center gap-2">
                        <Gavel size={20} className="text-indigo-400" />
                        <span className="text-lg font-medium text-gray-300">
                          {product.bids && product.bids.length > 0
                            ? "Current Bid:"
                            : "Minimum Bid:"}
                        </span>
                        <span className="text-xl font-bold text-indigo-400">
                          $
                          {product.bids && product.bids.length > 0
                            ? product.currentBid
                            : product.minimumBid}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <DollarSign size={20} className="text-emerald-400" />
                        <span className="text-lg font-medium text-gray-300">
                          Buy Now:
                        </span>
                        <span className="text-xl font-bold text-emerald-400">
                          ${product.buyNowPrice}
                        </span>
                      </div>
                    </div>

                    {!product.sold && (
                      <form onSubmit={handleBid} className="space-y-4">
                        <div>
                          <label
                            htmlFor="bidAmount"
                            className="block text-sm font-medium text-gray-300 mb-1"
                          >
                            Your Bid
                          </label>
                          <input
                            type="number"
                            id="bidAmount"
                            value={bidAmount}
                            onChange={(e) =>
                              setBidAmount(Number(e.target.value))
                            }
                            className="w-full px-3 py-2 rounded-lg input"
                            min={product.minimumBid}
                            step={getMinimumIncrement(product.currentBid)}
                          />
                          <p className="text-sm text-gray-500 mt-1">
                            Minimum increment: $
                            {getMinimumIncrement(product.currentBid)}
                          </p>
                        </div>

                        <div className="flex gap-3">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="flex-1 btn-primary py-2 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            Place Bid
                          </button>
                          <button
                            type="button"
                            onClick={handleBuyNow}
                            disabled={isSubmitting || buyNowDisabled}
                            className="flex-1 btn-success py-2 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            Buy Now
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
