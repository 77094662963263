import React, { useState } from 'react';
import { AlertCircle, DollarSign, Gavel, Clock, ChevronDown, ChevronUp } from 'lucide-react';

export function CollapsibleRules() {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full p-4 flex items-center justify-between hover:bg-gray-700/50 transition-colors"
      >
        <div className="flex items-center gap-2">
          <AlertCircle className="text-indigo-400" />
          <h3 className="text-lg font-medium text-gray-100">Auction Rules & Guidelines</h3>
        </div>
        {isExpanded ? (
          <ChevronUp className="text-gray-400" />
        ) : (
          <ChevronDown className="text-gray-400" />
        )}
      </button>
      
      {isExpanded && (
        <div className="p-6 pt-2 space-y-6 border-t border-gray-700">
          <div>
            <h4 className="text-md font-medium text-gray-100 flex items-center gap-2 mb-2">
              <Gavel size={18} className="text-indigo-400" />
              Bidding Rules
            </h4>
            <ul className="list-disc list-inside text-gray-300 space-y-2 text-sm">
              <li>Minimum bid increments are automatically calculated:
                <ul className="list-none pl-6 pt-1 text-gray-400">
                  <li>• For items under $40: $1 minimum increment</li>
                  <li>• For items $40-$99: $5 minimum increment</li>
                  <li>• For items $100+: $10 minimum increment</li>
                </ul>
              </li>
              <li>The "Buy Now" option is disabled once bids reach 75% of the Buy Now price</li>
              <li>All bids are final and cannot be retracted</li>
            </ul>
          </div>

          <div>
            <h4 className="text-md font-medium text-gray-100 flex items-center gap-2 mb-2">
              <Clock size={18} className="text-indigo-400" />
              Auction Timeline
            </h4>
            <ul className="list-disc list-inside text-gray-300 space-y-2 text-sm">
              <li>Each item has a specific end time displayed on its listing</li>
              <li>Bids can be placed until the auction end time</li>
              <li>The highest bidder at the end time wins the item</li>
            </ul>
          </div>

          <div>
            <h4 className="text-md font-medium text-gray-100 flex items-center gap-2 mb-2">
              <DollarSign size={18} className="text-indigo-400" />
              Payment & Collection
            </h4>
            <ul className="list-disc list-inside text-gray-300 space-y-2 text-sm">
              <li>Winners must pay for items according to the event's payment terms</li>
              <li>Items must be collected during the specified pickup times</li>
              <li>Shipping options, if available, will be listed in the event details</li>
            </ul>
          </div>

          <div className="mt-6 p-4 bg-gray-700/50 rounded-lg">
            <p className="text-amber-400 text-sm font-medium mb-2">Legal Agreement</p>
            <p className="text-gray-300 text-sm">
              By placing a bid, you enter into a legally binding agreement to purchase the item if you are the winning bidder. 
              Failure to complete the purchase may result in account suspension and legal action.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}