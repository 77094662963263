import { useState } from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/Tabs";
import { BuyerEventSelector } from "./BuyerEventSelector";
import { ItemList } from "./ItemList";
import { MyBids } from "./MyBids";
import { useUserEvents } from "../../hooks/useUserEvents";
import { format } from "date-fns";
import {
  Calendar,
  MapPin,
  ChevronDown,
  Clock,
  CreditCard,
  Truck,
  Info,
} from "lucide-react";

interface BuyerInterfaceProps {
  userId: string;
}

export function BuyerInterface({ userId }: BuyerInterfaceProps) {
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { userEvents } = useUserEvents(userId);

  const selectedEvent = userEvents.find((e) => e.id === selectedEventId);

  return (
    <div className="mt-8">
      <BuyerEventSelector
        userId={userId}
        selectedEventId={selectedEventId}
        onEventSelect={setSelectedEventId}
      />

      {selectedEventId && selectedEvent ? (
        <>
          <button
            onClick={() => setIsDetailsOpen(!isDetailsOpen)}
            className="mt-6 w-full bg-gray-800 rounded-lg p-4 text-left transition-colors hover:bg-gray-750"
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Info size={20} className="text-indigo-400" />
                <span className="text-lg font-medium text-gray-100">
                  Informations de l'événement
                </span>
              </div>
              <ChevronDown
                size={20}
                className={`text-gray-400 transition-transform ${
                  isDetailsOpen ? "transform rotate-180" : ""
                }`}
              />
            </div>
          </button>

          {isDetailsOpen && (
            <div className="mt-2 bg-gray-800/50 rounded-lg p-6 space-y-6">
              <div>
                <h2 className="text-xl font-semibold text-gray-100 mb-2">
                  {selectedEvent.title}
                </h2>
                <p className="text-gray-400">{selectedEvent.description}</p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div className="flex items-center gap-2">
                    <Calendar size={18} className="text-indigo-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-300">
                        Dates de l'événement
                      </p>
                      <p className="text-gray-400">
                        Du{" "}
                        {format(selectedEvent.startDate.toDate(), "dd/MM/yyyy")}{" "}
                        au{" "}
                        {format(selectedEvent.endDate.toDate(), "dd/MM/yyyy")}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <MapPin size={18} className="text-indigo-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-300">Lieu</p>
                      <p className="text-gray-400">{selectedEvent.location}</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Clock size={18} className="text-indigo-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-300">
                        Dates de visite
                      </p>
                      <p className="text-gray-400">
                        {selectedEvent.viewingDates.join(", ")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center gap-2">
                    <CreditCard size={18} className="text-indigo-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-300">
                        Méthodes de paiement
                      </p>
                      <p className="text-gray-400">
                        {selectedEvent.paymentMethods.join(", ")}
                      </p>
                    </div>
                  </div>

                  {selectedEvent.hasShipping && (
                    <div className="flex items-center gap-2">
                      <Truck size={18} className="text-indigo-400" />
                      <div>
                        <p className="text-sm font-medium text-gray-300">
                          Livraison
                        </p>
                        <p className="text-gray-400">
                          {selectedEvent.shippingDetails}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mt-6">
            <Tabs defaultValue="items" className="w-full">
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="items">Articles</TabsTrigger>
                <TabsTrigger value="bids">Mes enchères</TabsTrigger>
              </TabsList>
              <TabsContent value="items" className="mt-4">
                <ItemList eventId={selectedEventId} userId={userId} />
              </TabsContent>
              <TabsContent value="bids" className="mt-4">
                <MyBids eventId={selectedEventId} userId={userId} />
              </TabsContent>
            </Tabs>
          </div>
        </>
      ) : (
        <div className="text-center text-gray-400 mt-8">
          Sélectionnez un événement pour voir les détails
        </div>
      )}
    </div>
  );
}
