import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { Event } from "../types";

interface CreateEventData {
  title: string;
  description: string;
  location: string;
  startDate: Date;
  endDate: Date;
  viewingDates: string[];
  paymentMethods: string[];
  hasShipping: boolean;
  shippingDetails?: string;
  pickupDetails: string;
}

export function useEvents(userId?: string) {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        console.log("📊 Fetching events for userId:", userId);
        const eventsRef = collection(db, "events");
        const eventsQuery = query(
          eventsRef,
          where("organizerId", "==", userId)
        );

        const snapshot = await getDocs(eventsQuery);
        console.log(
          "📄 Raw Firebase response:",
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );

        const eventsData = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            startDate: data.startDate?.toDate?.() || new Date(data.startDate),
            endDate: data.endDate?.toDate?.() || new Date(data.endDate),
          };
        });

        console.log("✅ Processed events:", eventsData);
        setEvents(eventsData as Event[]);
      } catch (error) {
        console.error("❌ Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [userId]);

  const createEvent = async (eventData: CreateEventData) => {
    try {
      if (!userId) throw new Error("User ID is required to create an event");

      const eventsRef = collection(db, "events");
      const newEvent = {
        ...eventData,
        organizerId: userId,
        createdAt: new Date(),
      };

      const docRef = await addDoc(eventsRef, newEvent);
      // Mettre à jour l'état local immédiatement
      const createdEvent = {
        id: docRef.id,
        ...newEvent,
        startDate: Timestamp.fromDate(newEvent.startDate),
        endDate: Timestamp.fromDate(newEvent.endDate),
      } as Event;

      setEvents((prevEvents) => [createdEvent, ...prevEvents]);
      setLoading(false);
      return createdEvent;
    } catch (error) {
      console.error("Error creating event:", error);
      throw error;
    }
  };

  const updateEvent = async (eventId: string, eventData: Partial<Event>) => {
    try {
      if (!userId) throw new Error("User ID is required to update an event");

      const eventRef = doc(db, "events", eventId);
      await updateDoc(eventRef, eventData);

      // Mise à jour de l'état local
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId ? { ...event, ...eventData } : event
        )
      );

      return true;
    } catch (error) {
      console.error("Error updating event:", error);
      throw error;
    }
  };

  const deleteEvent = async (eventId: string) => {
    try {
      if (!userId) throw new Error("User ID is required to delete an event");

      const eventRef = doc(db, "events", eventId);
      await deleteDoc(eventRef);

      // Mise à jour de l'état local
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );

      return true;
    } catch (error) {
      console.error("Error deleting event:", error);
      throw error;
    }
  };

  const getEvent = async (eventId: string) => {
    const eventRef = doc(db, "events", eventId);
    const snapshot = await getDoc(eventRef);
    return snapshot.data() as Event;
  };

  return {
    events,
    loading,
    createEvent,
    updateEvent,
    deleteEvent,
    getEvent,
  };
}
