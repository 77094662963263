import { useState, useEffect, useRef } from "react";

interface LazyImageProps {
  thumbnailSrc: string;
  fullSrc: string;
  alt: string;
  className?: string;
}

export function LazyImage({
  thumbnailSrc,
  fullSrc,
  alt,
  className,
}: LazyImageProps) {
  const [currentSrc, setCurrentSrc] = useState(thumbnailSrc || fullSrc);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: "50px",
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    const loadImage = async () => {
      try {
        if (thumbnailSrc) {
          const thumbImg = new Image();
          thumbImg.src = thumbnailSrc;
          await new Promise((resolve) => {
            thumbImg.onload = resolve;
            thumbImg.onerror = resolve;
          });
          setCurrentSrc(thumbnailSrc);
        }

        const fullImg = new Image();
        fullImg.src = fullSrc;
        await new Promise((resolve, reject) => {
          fullImg.onload = resolve;
          fullImg.onerror = reject;
        });
        setCurrentSrc(fullSrc);
        setIsLoading(false);
      } catch (err) {
        console.error("Erreur de chargement:", err);
        setError(true);
        setIsLoading(false);
      }
    };

    loadImage();
  }, [isInView, thumbnailSrc, fullSrc]);

  return (
    <div ref={containerRef} className={`relative overflow-hidden ${className}`}>
      {currentSrc && (
        <img
          src={currentSrc}
          alt={alt}
          className={`
            w-full h-full object-cover
            transition-opacity duration-300
            ${isLoading ? "opacity-70" : "opacity-100"}
          `}
          onError={() => {
            setError(true);
            if (currentSrc === thumbnailSrc) {
              setCurrentSrc(fullSrc);
            }
          }}
        />
      )}

      {isLoading && !error && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
          <div className="w-8 h-8 border-2 border-indigo-500 border-t-transparent rounded-full animate-spin" />
        </div>
      )}

      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 text-gray-400">
          <span>Erreur de chargement</span>
        </div>
      )}
    </div>
  );
}
