import { useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  Package,
  DollarSign,
  Check,
  Clock,
  Image,
  ChevronDown,
} from "lucide-react";
import { Timestamp } from "firebase/firestore";
import toast from "react-hot-toast";

interface Product {
  id: string;
  name: string;
  price: number;
  paid: boolean;
  thumbnailUrl: string;
  soldAt?: Date;
}

interface Buyer {
  id: string;
  name: string;
  email: string;
  phone: string;
  products: Product[];
  itemsBought: number;
  totalSpent: number;
  unpaidAmount: number;
}

interface BuyerCardProps {
  buyer: Buyer;
  onMarkAsPaid: (productId: string) => Promise<void>;
}

export function BuyerCard({ buyer, onMarkAsPaid }: BuyerCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const formatDate = (date: Date | Timestamp | string | undefined) => {
    if (!date) return "Date invalide";
    try {
      let dateObj: Date;
      if (date instanceof Timestamp) {
        dateObj = date.toDate();
      } else if (date instanceof Date) {
        dateObj = date;
      } else {
        dateObj = new Date(date);
      }

      if (isNaN(dateObj.getTime())) {
        return "Date invalide";
      }
      return format(dateObj, "PPp", { locale: fr });
    } catch (error) {
      console.error("Erreur de formatage de date:", error);
      return "Date invalide";
    }
  };

  const handleMarkAsPaid = async (productId: string) => {
    try {
      await onMarkAsPaid(productId);
      toast.success("Article marqué comme payé");
    } catch (error) {
      console.error("Erreur lors du marquage du paiement:", error);
      toast.error("Erreur lors du marquage du paiement");
    }
  };

  return (
    <div className="bg-gray-800/30 rounded-xl p-6 border border-gray-700/50">
      <div
        className="flex justify-between items-start cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div>
          <h3 className="text-lg font-medium text-gray-100">{buyer.name}</h3>
          <p className="text-sm text-gray-400">{buyer.email}</p>
          <p className="text-sm text-gray-400">{buyer.phone}</p>
          <div className="flex items-center gap-4 mt-2 text-sm text-gray-300">
            <div className="flex items-center gap-1">
              <Package size={16} />
              <span>{buyer.itemsBought} articles</span>
            </div>
            <div className="flex items-center gap-1">
              <DollarSign size={16} />
              <span>
                {buyer.totalSpent.toLocaleString("fr-FR", {
                  style: "currency",
                  currency: "CAD",
                })}
              </span>
            </div>
            {buyer.unpaidAmount > 0 && (
              <div className="flex items-center gap-1 text-red-400">
                <Clock size={16} />
                <span>
                  {buyer.unpaidAmount.toLocaleString("fr-FR", {
                    style: "currency",
                    currency: "CAD",
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
        <button
          className="p-2 hover:bg-gray-700/30 rounded-lg transition-all duration-200"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          <ChevronDown
            size={20}
            className={`text-gray-400 transition-transform duration-200 ${
              isExpanded ? "transform rotate-180" : ""
            }`}
          />
        </button>
      </div>

      <div
        className={`mt-4 space-y-2 transition-all duration-200 overflow-hidden ${
          isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <h4 className="text-sm font-medium text-gray-300 mb-2">
          Historique des Achats
        </h4>
        <div className="space-y-2">
          {buyer.products.map((p) => (
            <div key={p.id} className="p-3 bg-gray-700/30 rounded-lg">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <div className="relative w-12 h-12 rounded-lg overflow-hidden bg-gray-800">
                    {p.thumbnailUrl ? (
                      <img
                        src={p.thumbnailUrl}
                        alt={p.name}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          const img = e.target as HTMLImageElement;
                          img.src = "/placeholder.jpg";
                          img.onerror = null;
                        }}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        <Image size={20} className="text-gray-500" />
                      </div>
                    )}
                  </div>
                  <div>
                    <span className="text-gray-100">
                      {p.num}-{p.name}
                    </span>
                    <div className="text-sm text-gray-400">
                      {formatDate(p.paid ? p.soldAt : undefined)}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <span className="font-semibold text-gray-100">
                    {p.price.toLocaleString("fr-FR", {
                      style: "currency",
                      currency: "CAD",
                    })}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkAsPaid(p.id);
                    }}
                    className={`p-2 rounded-lg transition-colors ${
                      p.paid
                        ? "bg-green-500/20 text-green-400"
                        : "bg-gray-600/50 hover:bg-gray-600 text-gray-400 hover:text-gray-100"
                    }`}
                    title={p.paid ? "Payé" : "Marquer comme payé"}
                  >
                    {p.paid ? <Check size={16} /> : <Clock size={16} />}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
