import { useState } from "react";
import { useEvents } from "../../hooks/useEvents";
import { useAuth } from "../../hooks/useAuth";
import { X } from "lucide-react";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";

interface CreateEventFormProps {
  onClose: () => void;
}

export function CreateEventForm({ onClose }: CreateEventFormProps) {
  const { user } = useAuth();
  const { createEvent } = useEvents(user?.uid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formData = new FormData(e.currentTarget);

      // Validation des dates
      const startDate = new Date(formData.get("startDate") as string);
      const endDate = new Date(formData.get("endDate") as string);

      if (endDate < startDate) {
        throw new Error("La date de fin doit être après la date de début");
      }

      const eventData = {
        title: formData.get("title") as string,
        description: formData.get("description") as string,
        location: formData.get("location") as string,
        startDate,
        endDate,
        viewingDates: (formData.get("viewingDates") as string)
          .split(",")
          .map((d) => d.trim())
          .filter(Boolean),
        paymentMethods: (formData.get("paymentMethods") as string)
          .split(",")
          .map((m) => m.trim())
          .filter(Boolean),
        hasShipping: formData.get("hasShipping") === "on",
        shippingDetails: (formData.get("shippingDetails") as string) || "",
        pickupDetails: formData.get("pickupDetails") as string,
      };

      console.log("Données de l'événement à créer:", eventData); // Pour le débogage

      const createdEvent = await createEvent(eventData);
      console.log("Événement créé:", createdEvent); // Pour le débogage

      toast.success("Événement créé avec succès");
      onClose();
    } catch (error) {
      console.error("Erreur lors de la création de l'événement:", error);
      toast.error(
        error instanceof Error
          ? error.message
          : "Échec de la création de l'événement"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 w-full max-w-2xl">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-100">
            Créer un nouvel événement
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-300"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Titre
            </label>
            <input
              type="text"
              name="title"
              required
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="Nom de l'événement"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Description
            </label>
            <textarea
              name="description"
              required
              rows={3}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="Description de l'événement"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Lieu
            </label>
            <input
              type="text"
              name="location"
              required
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="Adresse de l'événement"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Date de début
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date: Date | null) => setStartDate(date || new Date())}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
              minDate={new Date()}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Date de fin
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date: Date | null) => setEndDate(date || new Date())}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
              minDate={startDate}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Dates de visite (séparées par des virgules)
            </label>
            <input
              type="text"
              name="viewingDates"
              required
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="ex: 2024-03-15, 2024-03-16"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Méthodes de paiement (séparées par des virgules)
            </label>
            <input
              type="text"
              name="paymentMethods"
              required
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="ex: Carte, Espèces, Virement"
            />
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="hasShipping"
              id="hasShipping"
              className="w-4 h-4 bg-gray-700 rounded"
            />
            <label
              htmlFor="hasShipping"
              className="text-sm font-medium text-gray-300"
            >
              Livraison disponible
            </label>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Détails de livraison
            </label>
            <textarea
              name="shippingDetails"
              rows={2}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="Instructions de livraison (optionnel)"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Détails de retrait
            </label>
            <textarea
              name="pickupDetails"
              required
              rows={2}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-gray-100"
              placeholder="Adresse et instructions de retrait"
            />
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-gray-100"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? "Création..." : "Créer l'événement"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
