import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { Event } from "../types";

export function useUserEvents(userId?: string) {
  const [userEvents, setUserEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchUserEvents = async () => {
    if (!userId) {
      setLoading(false);
      return;
    }

    try {
      const userEventsRef = collection(db, "userEvents");
      const q = query(userEventsRef, where("userId", "==", userId));
      const snapshot = await getDocs(q);

      // Récupérer les IDs des événements
      const eventIds = snapshot.docs.map((doc) => doc.data().eventId);

      // Récupérer les détails des événements
      const eventsRef = collection(db, "events");
      const events = await Promise.all(
        eventIds.map(async (eventId) => {
          const eventDoc = await getDoc(doc(eventsRef, eventId));
          return { id: eventDoc.id, ...eventDoc.data() } as Event;
        })
      );

      setUserEvents(events);
    } catch (error) {
      console.error("Error fetching user events:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserEvents();
  }, [userId]);

  const addEventToUser = async (eventId: string) => {
    if (!userId) throw new Error("User ID is required");

    try {
      // Récupérer tous les événements
      const eventsRef = collection(db, "events");
      const eventsSnapshot = await getDocs(eventsRef);

      // Trouver l'événement qui correspond aux 8 premiers caractères
      const matchingEvent = eventsSnapshot.docs.find((doc) =>
        doc.id.startsWith(eventId)
      );

      if (!matchingEvent) {
        throw new Error("Événement non trouvé");
      }

      const fullEventId = matchingEvent.id;

      // Vérifier si l'utilisateur a déjà cet événement
      if (userEvents.some((event) => event.id === fullEventId)) {
        return false;
      }

      const userEventsRef = collection(db, "userEvents");
      await addDoc(userEventsRef, {
        userId,
        eventId: fullEventId,
        addedAt: new Date(),
      });

      const eventData = { id: fullEventId, ...matchingEvent.data() } as Event;
      setUserEvents((prev) => [...prev, eventData]);
      return true;
    } catch (error) {
      console.error("Error adding event to user:", error);
      throw error;
    }
  };

  const removeEventFromUser = async (eventId: string) => {
    if (!userId) throw new Error("User ID is required");

    try {
      const userEventsRef = collection(db, "userEvents");
      const q = query(
        userEventsRef,
        where("userId", "==", userId),
        where("eventId", "==", eventId)
      );
      const snapshot = await getDocs(q);

      await Promise.all(snapshot.docs.map((doc) => deleteDoc(doc.ref)));

      setUserEvents((prev) => prev.filter((event) => event.id !== eventId));
      return true;
    } catch (error) {
      console.error("Error removing event from user:", error);
      throw error;
    }
  };

  const refreshEvents = async () => {
    // Recharger les événements de l'utilisateur
    fetchUserEvents();
  };

  return {
    userEvents,
    loading,
    addEventToUser,
    removeEventFromUser,
    refreshEvents,
  };
}
