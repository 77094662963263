import { usePayments } from "../../hooks/usePayments";
import { format } from "date-fns";
import { Check } from "lucide-react";
import toast from "react-hot-toast";

interface PaymentTrackerProps {
  eventId: string;
  userId: string;
}

export function PaymentTracker({ eventId }: PaymentTrackerProps) {
  const { payments, loading, markAsPaid } = usePayments(eventId);

  const pendingPayments = payments.filter((payment) => !payment.isPaid);
  const completedPayments = payments.filter((payment) => payment.isPaid);

  const handleMarkAsPaid = async (paymentId: string) => {
    try {
      await markAsPaid(paymentId);
      toast.success("Paiement marqué comme reçu");
    } catch (error) {
      toast.error("Échec de la mise à jour du paiement");
    }
  };

  if (loading) {
    return (
      <div className="text-center text-gray-400">
        Chargement des paiements...
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-100">
          Suivi des Paiements
        </h2>
        <span className="text-sm text-gray-400">
          ID Événement: #{eventId.substring(0, 8)}
        </span>
      </div>

      <div className="grid gap-6">
        {/* Paiements en attente */}
        <div>
          <h3 className="text-lg font-medium text-gray-200 mb-4">
            Paiements en attente ({pendingPayments.length})
          </h3>
          <div className="space-y-4">
            {pendingPayments.map((payment) => (
              <div key={payment.id} className="bg-gray-700/50 rounded-lg p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium text-gray-100">
                      {payment.itemTitle}
                    </h4>
                    <div className="text-sm text-gray-400 mt-1">
                      Acheteur #{payment.buyerId.substring(0, 8)}
                    </div>
                    <div className="text-sm text-gray-400">
                      Dû le: {format(payment.dueDate, "PPP")}
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <span className="text-lg font-medium text-gray-100">
                      ${payment.amount.toFixed(2)}
                    </span>
                    <button
                      onClick={() => handleMarkAsPaid(payment.id)}
                      className="text-sm text-green-400 hover:text-green-300"
                    >
                      Marquer comme payé
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {pendingPayments.length === 0 && (
              <div className="text-center text-gray-400">
                Aucun paiement en attente
              </div>
            )}
          </div>
        </div>

        {/* Paiements complétés */}
        <div>
          <h3 className="text-lg font-medium text-gray-200 mb-4">
            Paiements reçus ({completedPayments.length})
          </h3>
          <div className="space-y-4">
            {completedPayments.map((payment) => (
              <div key={payment.id} className="bg-gray-800/50 rounded-lg p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="font-medium text-gray-100">
                      {payment.itemTitle}
                    </h4>
                    <div className="text-sm text-gray-400 mt-1">
                      Acheteur #{payment.buyerId.substring(0, 8)}
                    </div>
                    <div className="text-sm text-gray-400">
                      Payé le: {format(payment.paidAt!, "PPP")}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-lg font-medium text-gray-100">
                      ${payment.amount.toFixed(2)}
                    </span>
                    <Check className="text-green-400" size={20} />
                  </div>
                </div>
              </div>
            ))}
            {completedPayments.length === 0 && (
              <div className="text-center text-gray-400">
                Aucun paiement reçu
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
