import React, { useState, useRef } from "react";
import { X, Plus } from "lucide-react";

import { useProducts } from "../../hooks/useProducts";
import toast from "react-hot-toast";
import { Product } from "../../types";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";

interface EditProductFormProps {
  product: Product;
  onClose: () => void;
}

export function EditProductForm({ product, onClose }: EditProductFormProps) {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([
    ...product.imageUrls,
  ]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { updateProduct } = useProducts(product.eventId);
  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(product.endTime.seconds * 1000)
  );

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const newImages = Array.from(e.target.files);
    setSelectedImages((prev) => [...prev, ...newImages]);

    const newPreviewUrls = newImages.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);
  };

  const removeImage = (index: number) => {
    if (index < product.imageUrls.length) {
      // Suppression d'une image existante
      setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
    } else {
      // Suppression d'une nouvelle image
      const adjustedIndex = index - product.imageUrls.length;
      setSelectedImages((prev) => prev.filter((_, i) => i !== adjustedIndex));
      URL.revokeObjectURL(previewUrls[index]);
      setPreviewUrls((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      if (previewUrls.length === 0) {
        throw new Error("Au moins une image est requise");
      }

      const minimumBid = Number(formData.get("minimumBid"));
      const buyNowPrice = Number(formData.get("buyNowPrice"));

      if (minimumBid >= buyNowPrice) {
        throw new Error(
          "L'enchère minimum doit être inférieure au prix d'achat immédiat"
        );
      }

      await updateProduct(
        product.id,
        {
          title: formData.get("title") as string,
          description: formData.get("description") as string,
          minimumBid,
          buyNowPrice,
          endTime: selectedDate,
        },
        selectedImages
      );

      toast.success("Article mis à jour avec succès");
      onClose();
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Échec de la mise à jour"
      );
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg w-full max-w-2xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="itemNumber"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Numéro de produit
            </label>
            <input
              type="text"
              id="itemNumber"
              name="itemNumber"
              defaultValue={product.itemNumber}
              className="w-full px-3 py-2 rounded-lg input"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Titre
            </label>
            <input
              type="text"
              name="title"
              defaultValue={product.title}
              required
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Description
            </label>
            <textarea
              name="description"
              defaultValue={product.description}
              required
              rows={3}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Enchère minimum
              </label>
              <input
                type="number"
                name="minimumBid"
                defaultValue={product.minimumBid}
                min="0"
                step="0.01"
                required
                className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Prix d'achat immédiat
              </label>
              <input
                type="number"
                name="buyNowPrice"
                defaultValue={product.buyNowPrice}
                min="0"
                step="0.01"
                required
                className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-300 mb-2">
              Date et heure de fin
            </label>
            <DatePicker
              selected={selectedDate}
              onChange={(date: Date | null) =>
                setSelectedDate(date || new Date())
              }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd/MM/yyyy HH:mm"
              locale={fr}
              className="w-full px-3 py-2 rounded-lg input bg-gray-700 text-gray-100"
              placeholderText="Sélectionnez une date et heure"
              timeCaption="Heure"
              minDate={new Date()}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Images
            </label>
            <div className="grid grid-cols-4 gap-4 mt-2">
              {previewUrls.map((url, index) => (
                <div key={url} className="relative group">
                  <img
                    src={url}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-24 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <X size={14} />
                  </button>
                </div>
              ))}
              {previewUrls.length < 4 && (
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="w-full h-24 border-2 border-dashed border-gray-600 rounded-lg flex items-center justify-center text-gray-400 hover:text-gray-300 hover:border-gray-500"
                >
                  <Plus size={24} />
                </button>
              )}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
              className="hidden"
            />
          </div>

          <div className="flex justify-end gap-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-gray-300 rounded"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 disabled:opacity-50"
            >
              Mettre à jour
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
